import {Controller} from "@hotwired/stimulus"
import {Crepe} from '@milkdown/crepe';

const getCsrfToken = () => {
    const metas = document.getElementsByTagName('meta');
    for (let meta of metas) {
        if (meta.getAttribute('name') === 'csrf-token') {
            console.log('csrf-token:', meta.getAttribute('content'));
            return meta.getAttribute('content');
        }
    }
    return '';
}

export default class extends Controller {
    initialize() {
        console.log("editor:initialize")
        super.initialize();
    }

    connect() {
        console.log("editor:connect")
        super.connect();

        this.createCrepe();
    }

    disconnect() {
        console.log("editor:disconnect")
        super.disconnect();
    }

    createCrepe() {
        this.crepe = new Crepe({
            featureConfigs: {
                [Crepe.Feature.ImageBlock]: {
                    onUpload: async (file) => {
                        console.log("aaa")
                        const formData = new FormData();
                        formData.append('image[file]', file);
                        const response = await fetch("/images/create_xhr", {
                            method: "POST",
                            credentials: 'same-origin',
                            headers: {
                                'X-CSRF-Token': getCsrfToken()
                            },
                            body: formData,
                        })
                        const json = await response.json();
                        console.log(json);
                        return json['src'];
                    },
                }
            },
            root: document.getElementById('editor'),
            defaultValue: document.querySelector("#post_content").value,
        });

        this.crepe.create().then(() => {
            console.log('Editor created');
            // 文字を選択する時に出てくるツールバーボタンのtype属性を追加する
            setTimeout(() => {
                let toolbarButtons = document.querySelectorAll('.toolbar-item');
                toolbarButtons.forEach(button => {
                    button.setAttribute('type', 'button');
                    console.log('buttonにtype=buttonを追加')
                });
            }, 1000)
        });
    }

    submitHandler(event) {
        // event.preventDefault();
        console.log("editor#submitHandler");
        const markdown = this.crepe.getMarkdown();
        console.log(markdown);
        document.querySelector("#post_content").value = markdown;
        // this.crepe.editor.action((ctx) => {
        //     const editorView = ctx.get(editorViewCtx);
        //     const serializer = ctx.get(serializerCtx);
        //     console.log(serializer(editorView.state.doc));
        // });
    }

    destroyCrepe() {
        this.crepe.destroy();
    }
}
