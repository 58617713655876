// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

// import "trix" q qqq
var Trix = require("trix")
import "@rails/actiontext"
import "preline"

Trix.config.blockAttributes.default = {
    tagName: "p",
    breakOnReturn: true
}
Trix.config.blockAttributes.heading1 = {
    tagName: "h2",
    terminal: true,
    breakOnReturn: true,
    group: false,
}

document.addEventListener("turbo:load", function (event) {
    // Turboのロード後にPrelineの初期化をする
    // HSCarousel.autoInit();
    // HSRemoveElement.autoInit();
    // HSFileUpload.autoInit();
    // HSTabs.autoInit();
    HSCollapse.autoInit()
});